<template>
  <AriModal v-if="show" :modalTitle="prestation.name" @close="close()">
    <template #body>
        <div class="mt-xs" v-if="prestation.validityLength">
          <span>{{ $t("products.validity") }} </span>
          <b>{{ validityDuration(prestation) }}</b>
          <i v-if="isRenewable(prestation)" class="pl-xxs fa fa-arrow-rotate-right text-valid fs-12" :title="$t('cart.autoRenewal')"></i>
        </div>
        <div v-if="prestation.remainingCredit">
          <span>{{ $t("products.credits") }} </span>
          <b>{{ prestation.remainingCredit }}</b>
        </div>
        <div v-if="canShowActivitySection">
          <span>{{ $t("products.activities") }} </span>
          <b>{{ associatedActivities }}</b>
        </div>
        <div v-if="canShowZoneSection">
          <span>{{ $t("products.zones") }} </span>
          <b>{{ prestation.type === 'gift-card' ? zoneName : validityZone(prestation) }}</b>
        </div>
        <div v-if="prestation.description" class="mt-xs">
          <div> {{ $t('products.description') }}</div>
          <span v-html="prestation.descriptionHtml"></span>
        </div>
    </template>
    <template #footer>
      <AriButton class="w-full ariane-mt-base" :content="$t('close')" filled @click="close()" />
    </template>
  </AriModal>
</template>

<script>
import ProductsControl from '@/modules/products/mixins/productsControl.js';
import { mapGetters } from 'vuex';

export default {
  name: 'prestaDetailModal',
  mixins: [ProductsControl],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    selectedZone: String,
    prestation: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters('domain', ['isMultiSite']),
    ...mapGetters('cart', ['isRenewable']),
    associatedActivities () {
      if (!this.prestation.associatedActivities) {
        return '';
      }
      const activityNames = [];
      this.prestation.associatedActivities.map(activity => activityNames.push(activity.name));
      if (activityNames.length) {
        return activityNames.join(', ');
      }
      return this.$t('products.validForAllActivities');
    },
    zoneName () {
      if (this.selectedZone) return this.selectedZone;
      else return this.usersZoneDetails.clubName;
    },
    canShowActivitySection () {
      return this.prestation.type !== this.$CONSTANTS.PRODUCT_TYPES.VIDEO && this.prestation.type !== this.$CONSTANTS.PRODUCT_TYPES.GIFT_CARD;
    },
    canShowZoneSection () {
      return this.isMultiSite && this.prestation.type !== this.$CONSTANTS.PRODUCT_TYPES.VIDEO;
    }
  },
  methods: {
    close () {
      this.$emit('on-close');
    }
  }
};
</script>

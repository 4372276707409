export default {
  PAYMENT_MODES: {
    PREL: 'PREL'
  },
  PUBLIC_KEYS: {
    GOOGLE_ANALYTICS_INTERNAL: 'G-MZ3CS5BRTL'
  },
  CALENDAR_MODES: {
    SESSION: 'SESSION',
    AVAILABILITY: 'AVAILABILITY'
  },
  CATEGORIES: {
    ALL_SERVICES: ['seances', 'abo', 'gift-card'],
    SERVICES: 'Prestations',
    PRODUCTS: 'Produits',
    VIDEO: 'video',
    DECIPASS: 'decipass'
  },
  VIDEO_GROUPINGS: {
    ACTIVITY: 'activity',
    COACH: 'coach'
  },
  VIDEO_PROVIDERS: {
    VIMEO: 'vimeo',
    VIMEO_LINK: 'https://player.vimeo.com/video/',
    YOUTUBE: 'youtube',
    YOUTUBE_LINK: 'https://www.youtube.com/embed/'
  },
  DEVICE_FORMATS: {
    WEB: 'WEB',
    MOBILE: 'MOBILE'
  },
  DEVICE_FORMAT_BREAKPOINTS: {
    FROM_SM_TO_MD: 992,
    FROM_MD_TO_LG: 1280
  },
  STATUSES: {
    INACTIVE: 'INACTIVE',
    BOOKED: 'BOOKED',
    AVAILABLE: 'AVAILABLE',
    NOT_AVAILABLE_YET: 'NOT_AVAILABLE_YET',
    FULL: 'FULL',
    QUEUED: 'QUEUED',
    UNKNOWN: 'UNKNOWN'
  },
  SECTIONS_OF_DAY: {
    morning: {
      start: 0,
      finish: 11,
      name: 'morning'
    },
    day: {
      start: 12,
      finish: 16,
      name: 'day'
    },
    evening: {
      start: 17,
      finish: 23,
      name: 'evening'
    }
  },
  PRODUCT_TYPES: {
    SUBSCRIPTION: 'abo',
    SUBSCRIPTION2: 'subscription',
    PREPAID_CARD: 'seances',
    PREPAID_CARD2: 'prepaidCard',
    PREPAID_CARD3: 'prepaid-card',
    DROP_IN: 'Drop-in',
    SPOTLIGHT: 'spotlight',
    GIFT_CARD: 'gift-card',
    GOODS: 'goods',
    VIDEO: 'video'
  },
  PRODUCT_PRICING_FORMATS: {
    INSTALLMENTS: 'INSTALLMENTS',
    TOTAL: 'TOTAL',
    TOTAL_AND_UNIT_PRICE: 'TOTAL_AND_UNIT_PRICE'
  },
  BOOKING_TYPES: {
    BOOKING: 'BOOKING',
    WAITING_LIST: 'WAITING_LIST'
  },
  CALENDAR: {
    DAYS_IN_WEEK: 7,
    SECONDS_IN_A_MINUTE: 60,
    MILISECONDS_IN_A_MINUTE: 60 * 1000,
    COUNTDOWN_MINUTES_TO_SHOW_LIVESTREAM: 30,
    SUCCESSFUL_BOOKING: 'SUCCESSFUL_BOOKING'
  },
  APP_STATE: {
    INITIAL: 'INITIAL',
    LOADING: 'LOADING',
    LOADED: 'LOADED'
  },
  TUNNEL_LABELS: {
    UNCONNECTED_WELCOME_MODAL: 'unconnectedWelcomeModal',
    CONNECTED_WELCOME_MODAL: 'connectedWelcomeModal',
    NO_CORNER_LOGO: 'noCornerLogo',
    SINGLE_ZONE: 'singleZone',
    UNCONNECTED_WELCOME_PAGE: 'unconnectedWelcomePage',
    CONNECTED_WELCOME_PAGE: 'connectedWelcomePage',
    CALENDAR_BY_ZONE: 'calendarByZone',
    CALENDAR_BY_RESOURCE: 'calendarByResource',
    CALENDAR_BY_ACTIVITY: 'calendarByActivity',
    CALENDAR_BY_COACH: 'calendarByCoach',
    SHOP_BY_ZONE: 'shopByZone',
    CUSTOM_MENU: 'customMenu'
  },
  TUNNEL_OPTIONS: {
    FREE_PRODUCT_ON_SIGNUP: 'freeProductOnSignUp',
    PROMOTED_PRODUCTS: 'promotedProducts'
  },
  NOTIFICATION_METHODS: {
    MAIL_AND_SMS: 'O',
    NONE: 'N',
    MAIL: 'M',
    SMS: 'S'
  },
  BOOKING_MODES: {
    ONLY_SESSION: 'L',
    ONLY_RESOURCE_AVAILABILITY: 'T',
    BOTH_SESSION_AND_AVAILABILITY: 'O',
    NO_BOOKING: 'N'
  },
  SHOW_NB_PLACES: {
    NO_DISPLAY: 0,
    SHOW_REGISTERED_AMONG_TOTAL: 'SHOW_REGISTERED_AMONG_TOTAL', /* e.g : 4 / 20 participants */
    SHOW_ONLY_AVAILABLE: 'SHOW_ONLY_AVAILABLE', /* e.g : 4 remaining */
    SHOW_AVAILABLE_WHEN_ALMOST_COMPLETE: 'SHOW_AVAILABLE_WHEN_ALMOST_COMPLETE' /* show "4 remaining" only when 25% of capacity reached  */
  },
  USER_MESSAGE_TYPES: {
    RED_ERROR: 'error',
    YELLOW_WARNING: 'warning',
    GRAY_MESSAGE: 'message',
    GREEN_SUCCESS: 'success'
  },
  USER_MESSAGE_DURATIONS: {
    SHORT: 3000,
    NORMAL: 5000,
    LONG: 8000
  },
  API_MESSAGES: {
    TRANSACTION_ACCEPTED: 'ACCEPTED'
  },
  ERRORS: {
    LOGGEDIN_MONO_USER_WITH_NO_DOMAIN_ACCOUNT: 'LOGGEDIN_MONO_USER_WITH_NO_DOMAIN_ACCOUNT',
    ORDER: {
      CASH_PAYMENT_NOT_AVAILABLE: 'cash-payment-not-available'
    },
    API_ERROR_MESSAGES: {
      ERROR_401: 'Request failed with status code 401',
      ERROR_404: 'Request failed with status code 404',
      BOOKING_HAS_BEEN_CANCELLED_NOT_FOUND: 'This booking has been cancelled, This booking does not exist',
      BOOKING_NOT_AVAILABLE_BEFORE: 'This booking is no longer available',
      BOOKING_NOT_AVAILABLE_AFTER: 'This booking is not available yet',
      BOOKING_NOT_COMPLETE: 'This booking is not complete',
      BOOKING_COMPLETE: 'This booking is complete',
      BOOKING_NOT_FOUND: 'This booking does not exist',
      BOOKING_ON_SAME_TIME_SLOT:
        'This member already has a booking on this time slot',
      BOOKING_WRONG_MAX_DURATION: 'This booking does not meet the maximum deadline',
      BOOKING_WRONG_MIN_DURATION: 'This booking does not meet the minimum deadline',
      BOOKING_HAS_BEEN_CANCELLED: 'This booking has been cancelled',
      BOOKING_ZONE_BLACKLISTED:
        'This booking\'s zone is blacklisted and cannot be used by other zone\'s members',
      MEMBER_DONT_HAVE_VALID_PRODUCT: 'This member doesn\'t have valid product',
      MEMBER_HAS_NOT_REGISTERED: 'This member has no registration on this booking',
      MEMBER_HAS_REGISTERED: 'This member is already registered to this booking',
      MEMBER_HAS_REGISTERED_ON_WAITING_LIST:
        'This member is already registered to the waiting list of this booking',
      MEMBER_HAS_NOT_ENOUGH_CREDIT: 'This member has not enough credit',
      MEMBER_HAS_TOO_MANY_BOOKING_FOR_THE_DAY:
        'This member has too many bookings for this day',
      MEMBER_HAVE_TOO_MANY_CANCEL: 'This member has too many canceled booking',
      MEMBER_HAVE_UNPAID: 'This member has unpaid',
      MEMBER_IN_BLACKLIST: 'This member is black listed',
      MEMBER_MAX_BOOKING_PER_PERIOD_REACHED:
        'This member has reached max booking per period',
      MEMBER_BOOKING_TOO_EARLY:
        'This member has reached time limit between two booking',
      MEMBER_MAX_BOOKING_QUOTA_REACHED: 'This member has reached his quota',
      MEMBER_MAX_ACTIVITY_BOOKING_QUOTA_REACHED:
        'This member has reached his quota for this activity',
      MEMBER_NOT_FOUND: 'This member does not exist',
      MEMBER_IS_ANONYME: 'This member is anonyme',
      MEMBER_ONLINE_CANCELATION_DELAY_EXCEEDED:
        'The online cancellation delay is exceeded for this booking',
      MEMBER_CANCEL_QUOTA_REACHED:
        'This member has reached his quota of authorized absences or cancellations',
      MEMBER_ZONE_BLACKLISTED:
        'This member\'s zone is blacklisted and he cannot book to other zone\'s bookings',
      PLACE_NOT_AVAILABLE: 'This place is not available',
      PRODUCT_NOT_AVAILABLE_FOR_THIS_SPORT:
        'This product is not available for this activity',
      PRODUCT_NOT_AVAILABLE_FOR_THIS_ZONE:
        'This product is not available for this zone',
      RULE_NOT_FOUND: 'This rule does not exist',
      SCHEDULE_NOT_FOUND: 'This schedule is not available',
      RESOURCE_NOT_FOUND: 'This resource does not exist',
      RESOURCE_NOT_AVAILABLE_FOR_ACTIVITY:
        'This resource is not available for this activity',
      RESOURCE_NOT_AVAILABLE_FOR_DATE_AND_DURATION:
        'This resource is not available at this date for this duration',
      GENERAL_ERROR: 'an error occurred',
      CANNOT_ADD_TO_BOOKING_QUEUE: 'Members cannot add member to booking queue',
      INVITATIONS_NOT_AVAILABLE: 'You can\'t reserve many places for this activity'
    }
  },
  INVITATIONS: {
    AVAILABILITY: ['O', 'T'],
    SESSION: ['O', 'L']
  }
};

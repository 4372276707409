<template>
  <div class="h-full">
    <div v-if="!hasWebPayment" class="full-page-text">
      {{ $t('products.domainHasNoWebPayment') }}
      <div class="m-lg" v-if="!loggedInUser && isMultiSite">
        <AriButton
          filled
          :content="$t('shop.changeZone')"
          @click="redirectToSignUp()"
        />
      </div>
    </div>
    <div v-else id="shop-page" class="grid-shop" @click="removeCardFocus()">
      <div class="flex pb-sm grid-first-line items-center justify-between w-full" v-if="selectedZoneId">
        <div class="flex flex-col w-full gap-default">
          <div class="flex gap-default items-center hover:opacity-30 cursor-pointer" v-if="pendingResa && pendingResa.recommendedSubscriptions"
              @click="showModalDropResa = true">
            <i class="fa fa-chevron-left"/>
            <span>{{$t('shop.returnToCalendar')}}</span>
          </div>
          <div class="flex flex-col md:flex-row justify-between w-full items-baseline mt-sm">
            <div v-if="!pendingResa || !pendingResa.recommendedSubscriptions" class="flex flex-col lg:flex-row gap-xxs items-baseline">
              <span class="flex welcome-title ariane-pl-base ariane-pr-xs whitespace-nowrap">{{ $t('cart.welcome') }}</span>
              <span v-if="loggedInUser" class="flex welcome-title club-name whitespace-nowrap">{{ usersZoneDetails.clubName }}</span>
              <span v-else-if="getZonesWithWebPayment.length === 1" class="flex welcome-title club-name whitespace-nowrap">{{ getActiveZone(selectedZoneId).clubName }}</span>
              <AriDropdown v-else
                noBorder
                fitContent
                valueKey="id"
                labelKey="clubName"
                class="zone-selector zone-selector-custom self-center"
                :datas="getZonesWithWebPayment"
                :filterable="false"
                :clearable="false"
                v-model="selectedZoneId"
                @update:modelValue="setZone()"
              />
            </div>
            <span class="flex text-xl gap-xs whitespace-nowrap" v-else>
              <span class="text-secondary">{{$t('shop.prestationForThisActivity')}}</span>
              <span class="text-main font-medium">{{getPendingActivityName() || getPendingData.place}}</span>
            </span>
            <div class="flex flex-col md:flex-row md:items-center w-full md:w-auto">
              <div class="flex flex-col md:gap-xs w-full md:w-auto md:flex-col ml-auto" v-if="loggedInUser">
                <AriButton class="cart-btn" preIcon="filled fas fa-shopping-cart" :content="cartSize || ''" filled @click="goToCart()" />
                <div class="flex gap-xxs items-baseline self-end cursor-pointer" v-click-outside="() => { showGiftCardUI = false }">
                  <span @click="showGiftCardUI = !showGiftCardUI">{{ $t('cart.account')}}</span>
                  <div class="relative">
                    <span @click="showGiftCardUI = !showGiftCardUI" class="pl-xs text-lg font-bold text-main gift-card-cursor">
                      {{memberBalance}} {{ currency }}
                    </span>
                    <div class="gift-card-popover" v-if="showGiftCardUI" >
                      <vouchersHandler/>
                    </div>
                  </div>
                </div>
              </div>
              <leftMenuShop v-if="windowInnerWidth < 640" :products="displayedProducts" v-model:selectedCategory="selectedCategory"/>
            </div>
          </div>
        </div>
      </div>
      <div class="grid-left-column">
        <leftMenuShop v-if="windowInnerWidth > 640" :products="displayedProducts" v-model:selectedCategory="selectedCategory"/>
      </div>
      <div class="grid-content flex flex-col scroll" :class="{ 'take-all-col': windowInnerWidth < 641 }">
        <groupProducts v-if="displayedProducts" :selectedZoneId="selectedZoneId" :selectedCategory="selectedCategory" v-model:selectedCardId="selectedCardId" :products="displayedProducts"/>
        <span class="m-auto w-full text-secondary" v-else>{{ $t('shop.noProducts') }}</span>
      </div>
      <div class="grid-right-content"></div>
    </div>
    <AriModal v-if="showModalDropResa" :modalTitle="$t('shop.dropResa3')" @close="showModalDropResa = false">
      <template #body>
        <div>{{$t('shop.dropResa1')}}</div>
        <div v-if="prestasLinkedToTimeslot && prestasLinkedToTimeslot.length > 0">
          <span>{{$t('shop.dropResa2')}}</span>
          <ul class="pl-xs">
            <li v-for="(p, index) in prestasLinkedToTimeslot" :key="index">
              - {{p.quantity}}x {{p.config.name}}
            </li>
          </ul>
        </div></template>
      <template #footer>
        <div class="flex justify-between flex-col lg:flex-row">
          <AriButton :content="$t('app.return')" text neutral @click="showModalDropResa = false" />
          <AriButton error :content="$t('shop.drop')" @click="dropReservation()" />
        </div>
      </template>
    </AriModal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import vouchersHandler from '@/core/components/vouchersHandler.vue';
import ProductsControl from '@/modules/products/mixins/productsControl.js';
import groupProducts from './GroupProducts/groupProducts.vue';
import leftMenuShop from './components/leftMenuShop.vue';

export default {
  name: 'page.shop',
  components: { groupProducts, leftMenuShop, vouchersHandler },
  mixins: [ProductsControl],
  data () {
    return {
      selectedCategory: null,
      selectedZoneId: null,
      selectedCardId: null,
      showGiftCardUI: false,
      giftCardCode: null,
      giftCardState: null,
      giftCardValue: null,
      windowInnerWidth: window.innerWidth,
      showModalDropResa: false
    };
  },
  async mounted () {
    if (this.isMultiSite) {
      const zoneIdFromTunnel = this.tunnelConfigurations[this.$CONSTANTS.TUNNEL_LABELS.SHOP_BY_ZONE] ? parseInt(this.tunnelConfigurations[this.$CONSTANTS.TUNNEL_LABELS.SHOP_BY_ZONE]) : null;
      const onlyOneZoneWithWebpayment = this.getZonesWithWebPayment.length === 1 ? this.getZonesWithWebPayment[0].id : null; 
      this.selectedZoneId = zoneIdFromTunnel || onlyOneZoneWithWebpayment || this.getShopZone.id;
      this.setZone();
    } else {
      this.selectedZoneId = this.getShopZone.id;
      await this.prepareProducts();
    }

    if (!this.rearrangeUrlAfterTransaction()) {
      this.reactToTransactionResponse();
      // this.reactToActivityFilter();
      // this.reactToProductFilter();
      // this.reactToMemorisedProduct();
      if (this.loggedInUser) {
        this.loadUserWallet();
      }
    }
    this.INIT_PRODUCTS();
  },
  computed: {
    ...mapGetters('user', ['loggedInUser', 'usersZoneDetails']),
    ...mapState('cart', {
      pendingResa: 'pendingResa',
      cartProducts: 'products'
    }),
    ...mapGetters('config', ['currency', 'selectedZone']),
    ...mapGetters('domain', ['getZonesWithWebPayment', 'getActiveZone', 'isMultiSite']),
    ...mapState('zone', ['products', 'bookingSessions', 'resources', 'activities']),
    ...mapGetters('cart', ['cartSize']),
    ...mapState('config', ['tunnelConfigurations']),
    ...mapGetters('zone', ['getProductCategories']),
    ...mapState('user', ['userBalance', 'userDomainInfo']),
    memberBalance () {
      return this.userBalance?.balance || 0;
    },
    prestasLinkedToTimeslot () {
      if (!this.cartProducts) return [];
      else {
        const arrProductsInCartLinked = [];
        Object.keys(this.cartProducts).forEach(cp => {
          if (this.cartProducts[cp].isLinkedToTimeSlot) arrProductsInCartLinked.push(this.cartProducts[cp]);
        });
        return arrProductsInCartLinked;
      }
    },
    displayedProducts () {
      const tmpArr = [...this.getProductCategories];
      const onlineProducts = tmpArr?.filter(p => {
        return this.$CONSTANTS.CATEGORIES.ALL_SERVICES.includes(p.id) || (typeof p.id === 'number' && p.items?.length > 0);
      });
      if (!this.pendingResa) return onlineProducts;
      else {
        const arrayIdRecommended = [];
        const arrayGroupRecommendedId = [];
        this.pendingResa.recommendedSubscriptions.forEach(rs => arrayIdRecommended.push(rs.id));
        onlineProducts.forEach(productGroup =>
          productGroup.items.forEach(item => {
            const quantity = this.pendingResa.recommendedSubscriptions.find(rs => rs.id === item.id)?.quantity || 1;
            const isCard = item.type === this.$CONSTANTS.PRODUCT_TYPES.PREPAID_CARD2;
            const isSubscription = item.type === this.$CONSTANTS.PRODUCT_TYPES.SUBSCRIPTION2;
            const memberWillBeConverted = (isCard || isSubscription) && !!item.upgradeToMemberCat && (this.userDomainInfo.categoryId !== item.upgradeToMemberCat);
            const notAllowed = memberWillBeConverted && quantity > 1;
            if (arrayIdRecommended.includes(item.id) && !notAllowed) {
              const recommendedConfig = this.pendingResa.recommendedSubscriptions.find(rs => rs.id === item.id);
              if (!arrayGroupRecommendedId.find(rg => rg.id === productGroup.id)) {
                const cpProductGroup = { ...productGroup };
                cpProductGroup.items = [];
                cpProductGroup.items.push({ ...item, recommendedConfig });
                arrayGroupRecommendedId.push(cpProductGroup);
              } else {
                arrayGroupRecommendedId.find(rg => rg.id === productGroup.id).items.push({ ...item, recommendedConfig });
              };
            };
          })
        );
        return arrayGroupRecommendedId;
      }
    },
    catWithChildSelected () {
      if (this.selectedCategory) {
        if (this.$CONSTANTS.CATEGORIES.ALL_SERVICES.includes(this.selectedCategory) || this.selectedCategory === this.$CONSTANTS.CATEGORIES.SERVICES) return this.$CONSTANTS.CATEGORIES.SERVICES;
        else return this.$CONSTANTS.CATEGORIES.PRODUCTS;
      } else return null;
    },
    getPendingData () {
      let place, jsDate;
      const data = this.pendingResa?.resaDatas;
      if (data && data.schedule?.resourceId) {
        const session = this.resources.find(r => r.id === data.schedule.resourceId);
        if (session) {
          jsDate = new Date(data.schedule.date);
          place = session?.description;
        }
      } else if (data && data.bookingId) {
        const booking = this.bookingSessions.find(b => b.id === data.bookingId);
        if (booking) {
          jsDate = new Date(booking.startDate);
          place = booking?.description;
        }
      }
      const localDate = this.$datetime.getLocalNumericDate(jsDate);
      const localTime = this.$datetime.getLocalTime(jsDate);
      return place ? { place, localDate, localTime } : {};
    }
  },
  methods: {
    ...mapActions('user', ['loadUserWallet']),
    ...mapActions('zone', ['loadAllProducts', 'loadAllPublicProducts']),
    ...mapMutations('config', ['SET_SELECTED_ZONE']),
    ...mapMutations('cart', ['SET_PENDING_RESA', 'INIT_PRODUCTS']),
    getPendingActivityName () {
      const activityId = this.pendingResa?.resaDatas?.schedule?.activityId;
      return activityId ? this.activities.find(a => a.id === activityId)?.name : null;
    },
    dropReservation () {
      this.SET_PENDING_RESA(null);
      this.showModalDropResa = false;
      this.$router.push({ name: 'calendar' });
    },
    generateLeftMenu () {
      const menu = [
        { label: this.$CONSTANTS.CATEGORIES.SERVICES, categories: [] },
        { label: this.$CONSTANTS.CATEGORIES.PRODUCTS, categories: [] }
      ];
      this.getProductCategories.forEach(p => {
        if (this.$CONSTANTS.CATEGORIES.ALL_SERVICES.includes(p.id)) {
          menu[0].categories.push(p);
        } else {
          menu[1].categories.push(p);
        }
      });
      return menu;
    },
    useGiftCard () {
      this.$services.HTTP.vouchers.activateGiftCard(this.giftCardCode).then(res => {
        this.giftCardState = 'ENABLED';
      });
    },
    goToCart () {
      this.$router.push({ name: 'cart' });
    },
    setZone () {
      if (this.loggedInUser) {
        this.SET_SELECTED_ZONE(this.usersZoneDetails.id);
        this.loadAllProducts();
      } else {
        this.SET_SELECTED_ZONE(this.getActiveZone(this.selectedZoneId));
        this.loadAllPublicProducts({ zoneId: this.selectedZone.id });
      }
    },
    removeCardFocus () {
      this.selectedCardId = null;
    },
    redirectToSignUp () {
      return this.$router.replace({ name: 'signUp', query: { redirectToShop: true } });
    }
  }
};
</script>

<style>
.gift-card-popover {
  border: 1px solid;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08);
  @apply absolute right-2/4 z-10 gap-default p-md bg-content border-default rounded flex flex-col items-center justify-center;
}
.text-code-popover {
  @apply text-sm font-medium text-primary justify-center w-full flex;
}
#shop-page .zone-selector-custom .floux-div-selected-data {
  padding-left: var(--ariane-xs) !important;
}
#shop-page .zone-selector-custom .floux-select i {
  @apply text-base;
}
</style>

<style lang="less" scoped>
.warning-box {
  @apply flex items-center gap-default p-sm bg-pending rounded w-full;
}
.gift-card-cursor {
  @apply cursor-pointer;
  &:hover {
    text-decoration: underline;
  }
}
.zone-selector {
  font-size: var(--ariane-font-size-5);
  color: var(--ariane-primary-500) !important;
  border: none;
}
.cart-btn {
  margin: 0;
  margin-left: auto;
  padding: 4px 16px !important;
  font-size: 12px;
}
.grid-content {
  grid-row-start:2;
  grid-row-end:  2;
  grid-column-start: 2;
  grid-column-end: 3;
}
.grid-content.take-all-col {
    grid-column-start: 1;
}
.grid-shop {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 12% 76% 12%;
  grid-template-rows: auto 1fr;
  padding: 1rem;
  @apply bg-default;
}
.categorie-title {
  @apply text-primary font-semibold cursor-pointer;
  font-family: 'Ubuntu';
  font-style: normal;
  font-size: var(--ariane-font-size-2);
}
.categorie-sub-title {
  @apply text-secondary font-medium cursor-pointer;
  font-family: 'Ubuntu';
  font-style: normal;
  font-size: var(--ariane-font-size-1);
}
.welcome-title {
  @apply text-secondary text-lg self-center;
}
.grid-left-column {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  grid-row-start: 2;
  grid-row-end:  2;
  grid-column-start: 1;
  grid-column-end: 1;
  padding-top: 50px;
}
.grid-first-line {
  grid-row-start: 1;
  grid-row-end:  1;
  grid-column-start: 2;
  grid-column-end: 3;
}
.selected, .child-selected {
  opacity: 1;
  position: relative;
}
.selected:before {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  @apply bg-secondary-500;
  left: -10px;
  position: absolute;
}
@media (max-width: 1024px) {
  .grid-content {
    grid-column-start: 2;
    grid-column-end: 4;
  }
  .grid-first-line {
    grid-row-start: 1;
    grid-row-end:  1;
    grid-column-start: 2;
    grid-column-end: 4;
  }
}
@media (max-width: 768px) {
  .grid-first-line {
    grid-row-start: 1;
    grid-row-end:  1;
    grid-column-start: 1;
    grid-column-end: 4;
    flex-direction: column;
  }
  .club-name {
    padding-left: var(--ariane-base);
  }
}
.left-menu-btn {
  bottom: 20px;
  right: 20px;
  @apply absolute;
  width: fit-content;
}
</style>
